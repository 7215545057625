import {useEffect, useState} from 'react';

import {ScreenTypes} from '~constants/routes';
import useScreenType from '~hooks/useScreenType';

export default function useScreen() {
    const {isList} = useScreenType();
    const [screen, setScreen] = useState<ScreenTypes | undefined>(undefined);

    useEffect(() => {
        // Navigating straight to the list for the first time hangs.
        setScreen(isList ? ScreenTypes.List : ScreenTypes.Map);
    }, [isList]);

    return screen;
}
