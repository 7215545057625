import {useMemo} from 'react';
import {useRouter} from 'next/router';

import {ScreenTypes} from '~constants/routes';

export default function useScreenType() {
    const router = useRouter();

    return useMemo(
        () => ({
            isMap: router.query.slug?.[0] !== ScreenTypes.List,
            isList: router.query.slug?.[0] === ScreenTypes.List,
        }),
        [router],
    );
}
