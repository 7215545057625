import {useRouter} from 'next/router';
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {filtersFromQueryString, filtersToQueryString} from '~utils/filters';
import {PlotListFilters} from '~types/Plots';

export const filterableDistricts = ['1', '2', '3', '4', '5', '6'] as const;

export enum Order {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const unionFiltersOptions = {
    status: ['sold', 'notSold'] as const,
    myPlotsStatus: ['readyToClaim'] as const,
    myBidsStatus: ['isTopBid'] as const,
} as const;

export type FiltersType = {
    searchTerms: string;
    status: typeof unionFiltersOptions['status'][number] | null;
    myPlotsStatus: typeof unionFiltersOptions['myPlotsStatus'][number] | null;
    myBidsStatus: typeof unionFiltersOptions['myBidsStatus'][number] | null;
    price: Record<'min' | 'max', number | null>;
    distanceFromHq: Record<'min' | 'max', number>;
    districts: typeof filterableDistricts[number][];
    partner: string | null;
    sortDirection: PlotListFilters['sortDirection'] | null;
    sortBy: PlotListFilters['sortBy'] | null;
    searchQuery: string | null;
};

export const emptyFilters: FiltersType = {
    searchQuery: null,
    searchTerms: '',
    status: null,
    myPlotsStatus: null,
    myBidsStatus: null,
    price: {
        min: null,
        max: null,
    },
    distanceFromHq: {
        min: 0,
        max: 800,
    },
    districts: [],
    partner: null,
    sortDirection: 'ASC',
    sortBy: 'price',
};

export const initialFilters: FiltersType = {
    ...emptyFilters,
    status: null,
};

export type FiltersWhitelistType = (keyof FiltersType)[];

type FiltersContextType = {
    filters: FiltersType;
    initialFilters: FiltersType;
    emptyFilters: FiltersType;
    filtersWhitelist: FiltersWhitelistType;
    listFiltersWhitelist: FiltersWhitelistType;
    mapFiltersWhitelist: FiltersWhitelistType;
    setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
    setFilter: <T extends keyof FiltersType>(name: T, value: FiltersType[T]) => void;
    clearFilters: () => void;
};

const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);

type FiltersProviderProps = {
    listFiltersWhitelist: FiltersWhitelistType;
    mapFiltersWhitelist: FiltersWhitelistType;
    children: ReactNode;
    isList: boolean;
};

export const FiltersProvider = ({
    children,
    listFiltersWhitelist,
    mapFiltersWhitelist,
    isList,
}: FiltersProviderProps) => {
    const router = useRouter();
    const [filters, setFilters] = useState<FiltersType>(() => {
        const queryString = router.asPath.match(/\?.+/)?.[0];
        if (queryString) {
            return filtersFromQueryString(queryString);
        }

        return initialFilters;
    });

    // Track changes after initial state
    const isFiltersDirty = useRef(false);
    const filtersWhitelist = useMemo(
        () => (isList ? listFiltersWhitelist : mapFiltersWhitelist),
        [listFiltersWhitelist, mapFiltersWhitelist],
    );

    const setFilter = useCallback(
        <T extends keyof FiltersType>(name: T, value: FiltersType[T]) =>
            setFilters((current) => ({
                ...current,
                partner: emptyFilters.partner,
                [name]: value,
            })),
        [setFilters],
    );

    const clearFilters = useCallback(() => setFilters(emptyFilters), []);

    useEffect(() => {
        const queryString = filtersToQueryString(filters, filtersWhitelist);

        if (!isFiltersDirty.current) {
            if (
                queryString !==
                filtersToQueryString(initialFilters, filtersWhitelist)
            ) {
                isFiltersDirty.current = true;
            }
        }

        // Keep this check after setting isFiltersDirty
        if (
            queryString !== window.location.search.slice(1) &&
            isFiltersDirty.current
        ) {
            if (queryString) {
                window.history.pushState('', '', '?' + queryString);
            } else {
                if (window.location.href.includes('?')) {
                    window.history.pushState(
                        '',
                        '',
                        window.location.origin + window.location.pathname,
                    );
                }
            }
        }
    }, [filters, filtersWhitelist]);

    useEffect(() => {
        const queryString = window.location.search.slice(1);

        if (queryString) {
            setFilters(filtersFromQueryString(queryString));
        } else {
            setFilters(initialFilters);
        }
    }, [router.pathname]);

    return (
        <FiltersContext.Provider
            value={{
                filters,
                initialFilters,
                emptyFilters,
                filtersWhitelist,
                mapFiltersWhitelist,
                listFiltersWhitelist,
                setFilters,
                setFilter,
                clearFilters,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};

export const useFilters = () => useContext(FiltersContext);
