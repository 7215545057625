import {
    emptyFilters,
    FiltersType,
    FiltersWhitelistType,
    initialFilters,
    Order,
    unionFiltersOptions,
} from '~providers/FiltersProvider';

import {paramsToObject} from './paramsToObject';

type ApiFilters =
    // Global
    | 'minPrice'
    | 'maxPrice'
    | 'minDistanceFromHq'
    | 'maxDistanceFromHq'
    | 'districts[]'
    | 'searchQuery'
    // Main
    | 'sold'
    // Main map
    | 'partnerName'
    // My plots
    | 'readyToClaim'
    // My bids
    | 'isTopBid'
    // List sorting
    | 'sortBy'
    | 'sortDirection';

type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

function isNullOrUndefined(x: any): x is Exclude<any, null | undefined> {
    return typeof x === 'undefined' || x === null;
}

export function filtersToQueryString(
    allFilters: DeepPartial<FiltersType>,
    filtersWhitelist?: FiltersWhitelistType,
): string {
    const params: [ApiFilters, string | boolean | number][] = [];

    const filters = filtersWhitelist
        ? (Object.fromEntries(
              Object.entries(allFilters).filter(([filterName]) =>
                  filtersWhitelist.includes(filterName as keyof FiltersType),
              ),
          ) as Partial<FiltersType>)
        : allFilters;

    Object.keys(unionFiltersOptions).map(
        // @ts-ignore
        (filterName: keyof typeof unionFiltersOptions) => {
            const value = filters[filterName];
            if (value) {
                if (value === 'notSold') {
                    params.push(['sold', false]);
                } else {
                    params.push([value, true]);
                }
            }
        },
    );

    if (filters.price) {
        if (filters.price.min) {
            params.push(['minPrice', filters.price.min]);
        }

        if (!isNullOrUndefined(filters.price.max)) {
            params.push(['maxPrice', filters.price.max]);
        }
    }

    if (filters.distanceFromHq) {
        if (filters.distanceFromHq.min) {
            params.push(['minDistanceFromHq', filters.distanceFromHq.min]);
        }

        if (
            !isNullOrUndefined(filters.distanceFromHq.max) &&
            filters.distanceFromHq.max !== initialFilters.distanceFromHq.max
        ) {
            params.push(['maxDistanceFromHq', filters.distanceFromHq.max]);
        }
    }

    if (filters.districts?.length) {
        params.push(
            // @ts-ignore
            ...filters.districts.map((d) => ['districts[]', d]),
        );
    }

    if (filters.partner) {
        params.push(['partnerName', filters.partner]);
    }

    if (filters.sortBy) {
        params.push(['sortBy', filters.sortBy || 'price']);
        params.push(['sortDirection', filters.sortDirection || Order.ASC]);
    }

    if (filters.searchQuery) {
        params.push(['searchQuery', filters.searchQuery]);
    }

    return new URLSearchParams(
        params.map(([param, value]) => [param, value.toString()]),
    ).toString();
}

function toNumberOrNull(v: any) {
    if (v === null) {
        return null;
    }

    return Number(v);
}

const pick = <T>(allowed: T[], value: T | string | undefined, defaultValue: T): T =>
    allowed.includes(value as T) ? (value as T) : defaultValue;

export function filtersFromQueryString(
    queryString: string | undefined,
): FiltersType {
    const filters = {...emptyFilters};

    if (!queryString) {
        return filters;
    }

    const params = paramsToObject<ApiFilters>(
        // @ts-ignore
        new URLSearchParams(queryString).entries(),
    );

    Object.entries(unionFiltersOptions).map(
        // @ts-ignore
        ([filter, options]: [keyof typeof unionFiltersOptions, ApiFilters[]]) => {
            for (const option of options) {
                if (params[option] !== undefined && params[option] !== 'false') {
                    // @ts-ignore
                    filters[filter] = option;
                    return;
                }
            }
        },
    );

    filters.price = {
        min: toNumberOrNull(params.minPrice ?? initialFilters.price.min),
        max: toNumberOrNull(params.maxPrice ?? initialFilters.price.max),
    };

    filters.distanceFromHq = {
        min: Number(params.minDistanceFromHq ?? initialFilters.distanceFromHq.min),
        max: Number(params.maxDistanceFromHq ?? initialFilters.distanceFromHq.max),
    };

    if (params['districts[]']) {
        filters.districts = params['districts[]'].split(
            ',',
        ) as FiltersType['districts'];
    }

    if (params.partnerName) {
        filters.partner = params.partnerName as FiltersType['partner'];
    }

    if (params.sortBy) {
        filters.sortBy = pick(['price', 'auctionEnd'], params.sortBy, 'price');
        filters.sortDirection = pick<Order>(
            Object.values(Order),
            params.sortDirection,
            Order.ASC,
        );
    }

    return filters;
}
