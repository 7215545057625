import dynamic from 'next/dynamic';
import React from 'react';

import Layout, {LayoutProps} from '~components/organisms/Layout';
import {FiltersProvider, FiltersWhitelistType} from '~providers/FiltersProvider';
import {LoaderContainer} from '~components/organisms/MarsMap/styles';
import {Loader} from '~components/atoms/Loader';
import useScreen from '~pages/ListMapComboRoute/useScreen';
import type {MapPageProps} from '~organisms/MapPage';
import type {LandListProps} from '~organisms/LandList';
import useScreenType from '~hooks/useScreenType';

const MapList = dynamic(() => import('~pages/ListMapComboRoute/MapList'), {
    ssr: false,
    loading: () => (
        <LoaderContainer isLoading>
            <Loader bg="transparent" />
        </LoaderContainer>
    ),
});

type ListMapComboRouteProps = {
    listFiltersWhitelist: FiltersWhitelistType;
    mapFiltersWhitelist: FiltersWhitelistType;
    mapPageProps: MapPageProps;
    landListProps: LandListProps;
    securedPage?: boolean;
    layoutProps?: Omit<LayoutProps, 'menuProps' | 'showFooter' | 'securedPage'>;
};

export const ListMapComboRoute = ({
    listFiltersWhitelist,
    mapFiltersWhitelist,
    mapPageProps,
    landListProps,
    layoutProps,
    securedPage,
}: ListMapComboRouteProps) => {
    const {isList} = useScreenType();
    const screen = useScreen();

    return (
        <Layout
            {...layoutProps}
            securedPage={securedPage}
            menuProps={{background: isList}}
            contentProps={{withMapBackground: !isList}}
        >
            <FiltersProvider
                listFiltersWhitelist={listFiltersWhitelist}
                mapFiltersWhitelist={mapFiltersWhitelist}
                isList={isList}
            >
                <MapList
                    mapPageProps={mapPageProps}
                    landListProps={landListProps}
                    screen={screen}
                    securedPage={securedPage}
                />
            </FiltersProvider>
        </Layout>
    );
};
