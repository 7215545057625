import styled, {css} from 'styled-components';

import breakpoints from '~constants/breakpoints';
import LogoIcon from '~assets/images/logo/logo.svg';

export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
`;
export const Map = styled.div<{loaded?: boolean}>`
    background: #aa3d1a;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    transition: opacity ease-out 0.5s;
    opacity: ${({loaded}) => (loaded ? 1 : 0)};

    .mapboxgl-canvas-container {
        cursor: default !important;
    }
`;

export const MapFit = styled.div`
    width: 100%;
    height: 100%;

    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
    border-radius: 24px;

    aspect-ratio: 1/1;
    min-height: 300px;

    .mapboxgl-canvas-container {
        cursor: default !important;
    }
`;

export const Logo = styled(LogoIcon)`
    position: absolute;
    left: 20px;
    top: 20px;
    color: white;
    z-index: 3;
`;

export const Background = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    pointer-events: none;

    background-image: url('/assets/images/bg/map-bg-mobile.svg');
    background-position: top center;
    background-repeat: no-repeat;

    background-size: cover;

    ${breakpoints.abovePhoneS} {
        background-image: url('/assets/images/bg/map-bg.svg');
    }
`;

type LoaderContainerProps = {
    coverAll?: boolean;
    isLoading?: boolean;
};

export const LoaderContainer = styled.div<LoaderContainerProps>`
    position: fixed;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: opacity ease-in 2s;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        ${({coverAll}) =>
            coverAll
                ? css`
                      background-color: white;
                  `
                : css`
                      background-color: rgba(0, 0, 0, 0.2);
                  `}
    }

    ${({isLoading, coverAll}) =>
        isLoading
            ? css`
                  transition: opacity ease-out 1s;
                  pointer-events: all;
                  opacity: 1;

                  ${() =>
                      coverAll &&
                      css`
                          z-index: 99;
                      `}
              `
            : css`
                  pointer-events: none;
                  opacity: 0;
              `}
`;

export const NoPlotsFoundContainer = styled.div`
    position: fixed;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);

    top: 0;
    left: 0;

    z-index: 2;
`;
